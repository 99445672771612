<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <!-- 面包屑导航 -->
        <div class="class-nav">
          当前位置：
          <router-link tag="a" to="/">首页 </router-link>><router-link
            tag="a"
            to="/notice"
            >平台通知 </router-link
          >>
          {{ list.title }}
        </div>

        <!-- 培训介绍 -->
        <div class="class-box-1 class-box-2">
          <div class="class-box-1L">
            <div class="class-box-1L-1">{{ list.title }}</div>
            <!-- <div class="class-box-1L-2box" v-html="content">
              {{ content }}
            </div> -->
            <div class="class-box-1L-2box" v-html="list.content">
              {{ list.content }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { listNoticexq } from "@/api/bm/notice";

// import { listCoursepayment } from "@/api/bm/upload";
// import { getCourseJoinUser } from "@/api/bm/course";

// import { signTeacher, signStudent } from "@/api/bm/student";
// import QRCode from "qrcodejs2"; //二维码

export default {
  components: {
    // Nav,
    // Online,
    // Footer,
  },
  data() {
    return { list: [] };
  },
  //在这里调用axios请求方法
  created() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList() {
      let id = this.$route.query.id;
      console.log("请求通知列表x详情id", id);
      listNoticexq(id).then((res) => {
        console.log("请求通知列表x详情成功888888888888");
        console.log(res);
        this.list = res.data;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
  background: #f8f8f8;
  padding-top: 26px;
  padding-bottom: 138px;
  display: flex;
  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    .class-nav {
      margin-bottom: 37px;
    }
    .class-box-1 {
      height: 236px;
      // border: 1px solid teal;
      .class-box-1L {
        width: 1300px;
        height: auto;
        background: #ffffff;
        float: left;
        // padding-bottom: 70px;
        // position: relative;
        .class-box-1L-1 {
          width: 1178px;
          height: 57px;
          line-height: 57px;
          margin: 0 auto;
          background: #ffffff;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          //   box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 1px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          margin-bottom: 4px;
        }
        .class-box-1L-2 {
          width: 1300px;
          height: 175px;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
          padding-left: 114px;
          position: relative;
          .class-box-1L-2-1 {
            padding-top: 25px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            img {
              padding-right: 18px;
            }
          }
          .class-box-1L-2-4 {
            width: 190px;
            height: 41px;
            line-height: 41px;
            text-align: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            background: linear-gradient(
              -54deg,
              rgba(29, 112, 234, 0.89),
              rgba(72, 140, 240, 0.89)
            );
            border-radius: 4px;
            position: absolute;
            top: 98px;
            right: 79px;
            cursor: pointer;
          }
          // .classDetail-box {
          //   width: 100%;
          //   height: 550px;
          //   border: 1px solid teal;
          // }
        }
      }
      .class-box-1R {
        width: 262px;
        height: 236px;
        background: #ffffff;
        float: right;
        .class-box-1R-1 {
          width: 262px;
          height: 57px;
          line-height: 57px;
          padding-left: 19px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #000000;
          background: #ffffff;
          box-shadow: 0px 0px 23px 1px rgba(95, 95, 95, 0.06);
        }
        .class-box-1R-2 {
          img {
            width: 80px;
            height: 80px;
            background: #ffffff;
            border-radius: 50%;
            margin-left: 91px;
            margin-top: 24px;
          }
          .class-box-1R-2b {
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: 21px;
            .class-bs-1 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: bold;
              color: #000000;
            }
            .class-bs-2 {
              width: 1px;
              height: 20px;
              background: #c0c0c0;
              margin: 0 18px;
              display: inline-block;
            }
            .class-bs-3 {
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #000000;
            }
          }
        }
      }
    }
    .class-box-2 {
      margin-top: 27px;
      .class-box-1L {
        padding-bottom: 70px;
      }
      ::v-deep .class-box-1L-2box {
        width: 1178px;
        height: auto !important;
        margin: 0 auto;
        line-height: 30px;
        text-align: justify;
        // padding-top: 30px;
        img {
          width: 100%;
          height: 100%;
          // width: 550px;
          // height: 336px;
          // margin-left: 74px;
          // margin-top: 66px;
          // margin-bottom: 60px;
        }
        p {
          font-size: 15px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #27333b;
          line-height: 28px;
          margin-top: 32px;
          img {
            width: 100% !important;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
